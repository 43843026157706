<script setup>
import { computed, useSlots } from 'vue';
import JetSectionTitle from '@/Components/Section/SectionTitle.vue';

defineEmits(['submitted']);

const hasActions = computed(() => !!useSlots().actions);
</script>

<template>
    <div class="grid w-full gap-8">
        <JetSectionTitle>
            <template #title>
                <slot name="title" />
            </template>
            <template #description>
                <slot name="description" />
            </template>
        </JetSectionTitle>

        <form @submit.prevent="$emit('submitted')">
            <div
                class="px-4 py-5 bg-white sm:py-8 sm:px-10"
                :class="hasActions ? 'sm:rounded-tl-md sm:rounded-tr-md' : 'sm:rounded-md'"
            >
                <slot name="form" />
                <slot name="content" />
            </div>

            <div
                v-if="hasActions"
                class="
                    flex
                    items-center
                    justify-end
                    px-4
                    py-3
                    text-right
                    bg-gray-500-50
                    sm:px-6 sm:rounded-bl-md sm:rounded-br-md
                "
            >
                <slot name="actions" />
            </div>
        </form>
    </div>
</template>
